import classNames from 'classnames'
import React from 'react'
import {Spinner as TPASpinner, SpinnerProps as TPASpinnerProps} from 'wix-ui-tpa/cssVars'
import {classes} from './spinner.st.css'

interface SpinnerProps extends TPASpinnerProps {
  className?: string
  calendar?: boolean
  calendarButton?: boolean
}

export const Spinner = ({className, calendar, calendarButton, ...rest}: SpinnerProps) => {
  return (
    <TPASpinner
      {...rest}
      className={classNames(
        calendar ? classes.calendar : calendarButton ? classes.calendarButton : classes.list_LoadMoreButtonImprovements,
        className,
      )}
    />
  )
}
